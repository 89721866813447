<template>
  <div class="form-row">
    <FormRow>
      <template v-slot:label>メール受信設定</template>
      <template v-slot:toggle>
        <label class="form-toggle">
          <input
            class="form-toggle-input"
            type="checkbox"
            name="receptionEmailFlag"
            :checked="formData['receptionEmailFlag']"
            @change="handleChange($event, 'receptionEmailFlag')"
          />
          <span class="form-toggle-set">
            <span class="form-toggle-bg"></span>
            <span class="form-toggle-circle"></span>
          </span>
        </label>
      </template>
      <template v-slot:content>
        <div class="form-content-row">
          <p>
            OFF：通知メールを受信しません（パスワード変更/メール認証時を除く）
          </p>
        </div>
      </template>
    </FormRow>
    <FormRow>
      <template v-slot:label>メール認証設定</template>
      <template v-slot:toggle>
        <label class="form-toggle">
          <input
            class="form-toggle-input"
            type="checkbox"
            name="authEmailFlag"
            :checked="formData['authEmailFlag']"
            @change="handleChange($event, 'authEmailFlag')"
            :disabled="disabled"
          />
          <span class="form-toggle-set">
            <span class="form-toggle-bg"></span>
            <span class="form-toggle-circle"></span>
          </span>
        </label>
      </template>
      <template v-slot:content>
        <div class="form-content-row">
          <p>
            ON：ログイン時メールに届いた確認コードにて認証を行います
          </p>
        </div>
      </template>
    </FormRow>
    <FormRow v-if="hasReceptionStoreEmailFlag">
      <template v-slot:label>登録店舗メール受信設定</template>
      <template v-slot:toggle>
        <label class="form-toggle">
          <input
            class="form-toggle-input"
            type="checkbox"
            name="receptionStoreEmailFlag"
            :checked="formData['receptionStoreEmailFlag']"
            @change="handleChange($event, 'receptionStoreEmailFlag')"
          />
          <span class="form-toggle-set">
            <span class="form-toggle-bg"></span>
            <span class="form-toggle-circle"></span>
          </span>
        </label>
      </template>
      <template v-slot:content>
        <div class="form-content-row">
          <p>OFF：グループに参加している店舗にて通知メールを受信しません</p>
          <p>（加盟店審査時/パスワード変更/メール認証時を除く）</p>
        </div>
      </template>
    </FormRow>
  </div>
</template>

<script>
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'UserMailSetting',
  components: {
    FormRow,
  },
  props: {
    formData: {
      type: Object,
      required: true,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasReceptionStoreEmailFlag: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(e, name) {
      this.$emit('updateData', { [name]: e.target.checked });
    }
  },
}
</script>
